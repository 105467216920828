const MenuItems = [
  {
    Title: 'Forms Related to Stock Broking',
    items: [
      {
        label: 'Trading Account Opening Form (KYC) For Individual',
        href: '/downloads/NewIndividualKYC.pdf',
      },
      {
        label: 'Trading Account Opening Form (KYC) For Institution',
        href: '/downloads/KYC_Corporate_New.pdf',
      },
      {
        label: 'Buy-Sell Order Form',
        href: '/downloads/Buy-SellOrderForm.pdf',
      },
      {
        label: 'Online Agreement Form',
        href: '/downloads/ABC_Agreement.pdf',
      },
      {
        label: 'Weighted Average Cost Calculation (WACC) Form',
        href: '/downloads/WACC.pdf',
      },
    ],
  },
  {
    Title: 'Forms Related to Depository Service',
    items: [
      {
        label: 'Demat Account Opening Form For Individual',
        href: '/downloads/DematAccountOpeningForm_Individual.pdf',
      },
      {
        label: 'Demat Account Opening Form For Institution/ Corporate',
        href: '/downloads/DEMAT_CORPORATE.pdf',
      },
      {
        label: 'Demat Online Form (Mero Share Registration Form)',
        href: '/downloads/DematOnlineForm.pdf',
      },
      {
        label: 'Demat Online Form to Link Account',
        href: '/downloads/DmeatOnlineForm_LinkAccount.pdf',
      },
      {
        label: 'Demat Request Form (DRF)',
        href: '/downloads/DematRequestForm.pdf',
      },
      {
        label: 'Bank Account Update Form',
        href: '/downloads/BankAccountUpdateForm_Demat.pdf',
      },
    ],
  },
]

const OfflineDownloads = () => {
  return (
    <div className="my-10">
      <h1 className="text-4xl text-center mb-10">Offline Downloads</h1>
      <div className="flex justify-around  align-left text-left flex-wrap px-8">
        {MenuItems.map((item, index) => {
          return (
            <div key={index} className="mt-10">
              <h1 className="bold text-2xl mb-5">{item.Title}</h1>
              <ul>
                {item.items.map((subitem, subindex) => {
                  return (
                    <li key={subindex} className="my-2 color-blue">
                      <a
                        href={subitem.href}
                        className="text-blue-600/100"
                        target="_blank"
                        rel="noreferrer">
                        {subitem.label}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OfflineDownloads
