import { officeInfos, openingHours } from 'constants/pages/contact-us'
// Classes for the heading component
const headingClass = 'font-secondary font-medium text-2xl leading-[48px] mb-3.5'

const ContactInformation = () => {
  return (
    <div className="flex flex-col items-start 550:flex-row 550:justify-around gap-5 col-span-12 1100:flex-col 1100:col-span-3 text-left">
      <div className="flex flex-col 900:flex-row gap-5 1100:flex-col">
        {officeInfos.map(({ office, infos }) => (
          <div className="" key={office}>
            <h3 className={headingClass}>{office}</h3>
            <ul className="font-primary">
              {infos.map((info) => (
                <li key={info.data} className="flex items-center mb-2">
                  <img src={info.icon} alt="icon" />
                  {
                    info.href ? 
                      <a className="ml-2.5" href={info.href} target='_blank'>{info.data}</a> :
                      <span className="ml-2.5">{info.data}</span>
                  }
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="">
        <h3 className={`${headingClass}`}>Opening hours</h3>
        <ul className="font-primary">
          {openingHours.map((hour) => (
            <li key={hour.day} className="text-base leading-[32px] mb-2">
              <span className="font-semibold">{hour.day}:</span>
              <span className="ml-1 opacity-60">{hour.time}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ContactInformation
